import * as React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import priceCalculation from "./price_calculation";
import CustomModal from "./carosal_modal";



const e = React.createElement;
const axios = require('axios').default;

class Main_action_container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: true,
    };
    
  }

  processMessage(message) {
    if (message === "OK") {
      Swal.fire("Success!", `Thank you for your interest in InsidePacket.
      One of our representatives will get back to you.`, "success");
    } else {
      Swal.fire(
        "Error!",
        "There was a problem sending the mail. Please try again or contact the support",
        "error"
      );
    }
  }

  getArrayForCAM = (value) => {
    const returnedArr = [];

    value?.map((el)=>{
      if (el > 0) {
        returnedArr.push(el);
      }
    });

    return returnedArr
  }

  sendEmail = () => {
    const {storage, isForCustomerView} = this.props;
    const {is90sec} = storage;

    let formData = new FormData();
    if (this.getArrayForCAM(storage?.branchValuesList)?.length > 0) {
      formData.append('branches', this.getArrayForCAM(storage?.branchValuesList));
    }
    if (this.getArrayForCAM(storage?.dataCentersValueList)?.length > 0) {
      formData.append('dataCenters', this.getArrayForCAM(storage?.dataCentersValueList));
    }
    formData.append('name', storage?.customerName?.split(' ')?.join('_'));
    formData.append('customConnections', storage?.customCloudConnectivityValuesList);
    storage?.isAWS === true && formData.append('isAWS', storage?.isAWS);
    storage?.isGCP === true && formData.append('isGCP', storage?.isGCP);
    storage?.isAzure === true && formData.append('isAzure', storage?.isAzure);

    if (isForCustomerView) {
      if (is90sec) {
        location.href = 'https://www.insidepacket.com/details-form/';
      }
      else {
        const logoURL = document.getElementById("file").getAttribute("fileURL");
        const attachmentsArr = [];
        if (logoURL) {
          attachmentsArr.push({ name: "logo.jpg", data: logoURL });
        }
        Email.send({
          Host: "smtp.gmail.com",
          Username: "insidepacketmailagent@gmail.com",
          Password: "mytestpassword111",
          Port: "587",
          To: "sales@insidepacket.com",
          From: "insidepacketmailagent@gmail.com",
          Subject: "Inside packet onboard info!",
          Body: this.props.storage,
          Attachments: attachmentsArr,
        }).then((message) => this.processMessage(message));
      }
    }
    else {
      axios({
        method: 'post',
      
        // for release
        url: 'https://mssp-calc.insidepacket.com/api/createTenant',
        // for develop
        // url: 'http://3.140.223.13/api/createTenant',
        data: formData,
      }).then((response) => {
          Swal.fire("Success!", "A tenant was created successfully!", "success");
      
      }).catch((error) => {
        Swal.fire(
          "Error!",
          "There was a problem creating a tenant. Please try again or contact the support",
          "error"
        );
      });
    }




  };

  getActualCalcValue(val) {
    if (val === true) {
      return 1;
    }
    if (val === false) {
      return 0;
    }
    return Number(val);
  }

  getJSONValue = (val) => {
    return eval("(function(){return " + val + ";})()");
  };

  displayWithUnits = (value) => {
    if (value > 1000) {
      return `${value/1000}G`
    }

    return `${value}M`
  }

  // componentDidMount(){
  //   if(this.props?.isForCustomerView){
      
  //     hbspt.forms.create({ 
  //       region: "na1", 
  //       portalId: "24134740", 
  //       formId: "c310111f-af6e-4dfd-ba9b-b9ebff8827a3" 
  //       })
  //   }
    
  // }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
        region: "na1", 
        portalId: "24134740", 
        formId: "c310111f-af6e-4dfd-ba9b-b9ebff8827a3" ,
        target: "#hubspotForm",
        css:"stylingform"
        });
      }
    });
  }
  handleToggle = () => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  };
  handlerCustomer=()=>{
   const{customerName}= this.props.storage
   if(customerName){
    console.log(customerName,"fg")
    if(this.props.isForCustomerView&&customerName[0]==9&&customerName[1]==0){
      return true
    }else{
      return false
    }
   }
   return false
  }
  render() {
    const { isChecked } = this.state;

    const switchStyle = {
      display: 'inline-block',
      width: '50px',
      height: '25px',
      backgroundColor: isChecked ? '#6e39df' : '#6e39df',
      borderRadius: '25px',
      position: 'relative',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    };

    const knobStyle = {
      width: '21px',
      height: '21px',
      backgroundColor: 'white',
      borderRadius: '50%',
      position: 'absolute',
      top: '2px',
      left: isChecked ? '27px' : '2px',
      transition: 'left 0.3s ease',
    };
    const {
      isFWaaS,
      isIPS,
      isWebClassification,
      isAntiPhishing,
      isMultiCloud,
      isSecuredWebFiltering,
      isManagedSOC,
      isDDOSProtection,
      branchValuesList,
      dataCentersValueList,
      remoteUsers,
      isBOX,
      isSalesforce,
      isOffice,
      customSAASConnectivityValuesList,
      isAWS,
      isAzure,
      isGCP,
      customCloudConnectivityValuesList,
      markup = 0,
    } = this.props.storage;
    const {isForCustomerView} = this.props;
    const discountMultiplier = (100-markup)/100;
    const objToPass = {
      fromCodeFWaaS: this.getActualCalcValue(isFWaaS),
      fromCodeIPS: this.getActualCalcValue(isIPS),
      fromCodewebClassification: this.getActualCalcValue(isWebClassification),
      fromCodeantiPhishing: this.getActualCalcValue(isAntiPhishing),
      fromCodemultiCloud: this.getActualCalcValue(isMultiCloud),
      fromCodesecuredWebFiltering: this.getActualCalcValue(
        isSecuredWebFiltering
      ),
      fromCodemanagedSOC: this.getActualCalcValue(isManagedSOC),
      fromCodeDDosProtection: this.getActualCalcValue(isDDOSProtection),
      fromCodebranch1: this.getActualCalcValue(branchValuesList?.[0]),
      fromCodebranch2: this.getActualCalcValue(branchValuesList?.[1]),
      fromCodebranch3: this.getActualCalcValue(branchValuesList?.[2]),
      fromCodebranch4: this.getActualCalcValue(branchValuesList?.[3]),
      fromCodebranch5: this.getActualCalcValue(branchValuesList?.[4]),
      fromCodeDc1: this.getActualCalcValue(dataCentersValueList?.[0]),
      fromCodeDc2: this.getActualCalcValue(dataCentersValueList?.[1]),
      fromCodeDc3: this.getActualCalcValue(dataCentersValueList?.[2]),
      fromCodeDc4: this.getActualCalcValue(dataCentersValueList?.[3]),
      fromCodeDc5: this.getActualCalcValue(dataCentersValueList?.[4]),
      fromCodeRemoteUsers: this.getActualCalcValue(remoteUsers),
      fromCodeBox: this.getActualCalcValue(isBOX),
      fromCodeSalesforce: this.getActualCalcValue(isSalesforce),
      fromCodeOffice: this.getActualCalcValue(isOffice),
      fromCodeCustomSAAS1: this.getActualCalcValue(
        this.getJSONValue(customSAASConnectivityValuesList)?.[0]?.value
      ),
      fromCodeCustomSAAS2: this.getActualCalcValue(
        this.getJSONValue(customSAASConnectivityValuesList)?.[1]?.value
      ),
      fromCodeCustomSAAS3: this.getActualCalcValue(
        this.getJSONValue(customSAASConnectivityValuesList)?.[2]?.value
      ),
      fromCodeCustomSAAS4: this.getActualCalcValue(
        this.getJSONValue(customSAASConnectivityValuesList)?.[3]?.value
      ),
      fromCodeAWS: this.getActualCalcValue(isAWS),
      fromCodeAzure: this.getActualCalcValue(isAzure),
      fromCodeGCP: this.getActualCalcValue(isGCP),
      fromCodeCustomCloud1: this.getActualCalcValue(
        this.getJSONValue(customCloudConnectivityValuesList)?.[0]?.value
      ),
      fromCodeCustomCloud2: this.getActualCalcValue(
        this.getJSONValue(customCloudConnectivityValuesList)?.[1]?.value
      ),
      fromCodeCustomCloud3: this.getActualCalcValue(
        this.getJSONValue(customCloudConnectivityValuesList)?.[2]?.value
      ),
      fromCodeCustomCloud4: this.getActualCalcValue(
        this.getJSONValue(customCloudConnectivityValuesList)?.[3]?.value
      ),
    };
    console.log("this.props?.remoteuser",this.props?.storage.remoteUsers)
    console.log("this.props?.branchValuesList",this.props?.storage.branchValuesList)
    

    return (
<>
     {isForCustomerView&&this.handlerCustomer()&& <div class={isForCustomerView&&this.handlerCustomer()?"":"main_form_action"} style={isForCustomerView&&this.handlerCustomer()?{height: "325px",
        borderTop: "1px solid #e2e2e8",
        marginTop:"43px",
        
        padding: "15px 20px"}:{height: "325px",
        borderTop: "1px solid #e2e2e8",
        marginTop:"43px",
        
        padding: "15px 20px"}}>
       {isForCustomerView&&this.handlerCustomer()&&<div id={"hubspotForm"}></div>}
      </div>}
      { !this.handlerCustomer()&&   <div class={!this.handlerCustomer()?"":"main_form_action"} style={ !this.handlerCustomer()?{
         borderTop: "1px solid #e2e2e8",
         marginTop:"43px",
         width:"100%",
         height:"187px",
         padding: "15px 20px"}:{height: "187px",
         borderTop: "1px solid #e2e2e8",
         marginTop:"43px",
         
         padding: "15px 20px"}}>
        <div ><h2 style={{fontSize:"35px",fontWeight:"bold",color:"black",marginBottom:"26px",marginTop:"30px"}}>Simple,Transparent Pricing</h2>
        
          <div style={{display:'flex',justifyContent:'space-between',width:"100%",alignItems:"center"}}>
            <div style={{display:"flex",alignItems:"center",marginLeft:"10px"}}>
            <span style={{fontSize:"18px",fontWeight:"600",color:"black",marginRight:"10px"}}>Preferred billing cycle: </span>
          <span style={{fontWeight:"600",marginRight:"12px",color: !isChecked ? '#6e39df' : 'black'}}>Monthly</span>
    <div style={switchStyle} onClick={this.handleToggle}>
        <div style={knobStyle}></div>
      </div>
          <span style={{fontWeight:"600",marginLeft:"12px",marginRight:"12px", color: isChecked ? '#6e39df' : 'black'}}>yearly</span>
          <span style={{paddingLeft:"10px",paddingRight:"10px",paddingTop:"5px",paddingBottom:"5px",borderRadius:"15px",backgroundColor:"green",color:'white'}}> Save ${2*Number(this.props.remoteuser+(+this.props?.storage.branchValuesList))} with yearly</span>
          
              </div>
              <div style={{display:'flex',alignItems:"center",marginRight:"60px"}}>
              <div style={{display:"flex",alignItems:"center",marginRight:"5px"}}>
              <span style={{fontSize:"51px",fontWeight:"bold"}}>{isChecked ?"$10":"$12"}</span></div>
              
              <div><p style={{fontSize:"15px",fontWeight:"bold",color:'black'}}>Per user</p><p style={{fontSize:"15px",fontWeight:"bold",color:'black'}}>Per month</p>
        
                </div>
                </div>
               
        
        </div>
        <div style={{marginTop:"30px",textAlign:"end",marginRight:"31px"}}>
         Total of ${isChecked?10*Number(this.props.remoteuser+(+this.props?.storage.branchValuesList)):12*Number(this.props.remoteuser+(+this.props?.storage.branchValuesList))} {isChecked?"per month billed yearly":"per month billed monthly"}
          </div>
        </div>
        
      
     

        
       
      
      
      </div>  
    }             
      </>
    );
  }
}

export default Main_action_container;
