import ReactDOM from "react-dom";
import * as React from "react";
import Modal from 'react-modal';
import Swal from "sweetalert2";
import { sha256 } from 'js-sha256';
import { Scrollbars } from 'react-custom-scrollbars-2';
import CustomModal from "./carosal_modal";

const e = React.createElement;
const settingsWindowStyle = {
  content: {
    width: '23rem',
    height: '30rem',
    top: '15rem',
    left: '50%'
  }
};

const axios = require('axios').default;
axios.defaults.baseURL = 'https://mssp-calc.insidepacket.com/';

class Main_area_container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileEncodedURL: undefined,
      customerName: undefined,
      isSave: true,
      is90sec: false,
      isDDOSProtection:false,
      isLinksOpen: false,
      isSettingsOpen: false,
    };
  }

  componentDidMount() {
    const { customerName, fileEncodedURL, isSave, isDDOSProtection } = this.props.storage;
    if (fileEncodedURL) {
      const addLogoBackground = document.getElementById("addLogoBackground");
      addLogoBackground.innerHTML = "";
      addLogoBackground.style.border = "none";
      addLogoBackground.style.background = "none";
      document.getElementById(
        "file"
      ).style.backgroundImage = `url(${fileEncodedURL})`;
    }
    const exportButton = document.getElementById("exportButton");
    exportButton.addEventListener("change", this.exportFile);
    if (customerName) {
      this.setState({ customerName });
    }
    



    if (isSave === undefined) {
      this.props.updateStorageItem('isSave', this.state.isSave);
      this.props.updateStorageItem('is90sec', this.state.is90sec);
      this.props.updateStorageItem('isDDOSProtection',this.state.isDDOSProtection);

    }
  }

  exportFile = (event) => {
    const input = event.target;
    if ("files" in input && input.files.length > 0) {
      this.setParamsFromFile(input.files[0]);
    }
  };

  setParamsFromFile = (file) => {
    const { updateDatabase, storage } = this.props;
    this.readFileContent(file)
      .then((content) => {
        const obj = storage;
        const file = JSON.parse(content);
        obj.branchValuesList = file.branchValuesList;
        obj.dataCentersValueList = file.dataCentersValueList;
        obj.isAWS = file.isAWS;
        obj.isAntiPhishing = file.isAntiPhishing;
        obj.isAzure = file.isAzure;
        obj.isFWaaS = file.isFWaaS;
        obj.isGCP = file.isGCP;
        obj.isIPS = file.isIPS;
        obj.isManagedSOC = file.isManagedSOC;
        obj.isMultiCloud = file.isMultiCloud;
        obj.isSecuredWebFiltering = file.isSecuredWebFiltering;
        obj.isWebClassification = file.isWebClassification;
        obj.remoteUsers = file.remoteUsers;
        obj.isDDOSProtection = file.isDDOSProtection;
        obj.isBOX = file.isBOX;
        obj.isCustomerView = file.isCustomerView,
          obj.markup = file.markup,
          obj.isSalesforce = file.isSalesforce;
        obj.isOffice = file.isOffice;
        obj.customerName = file.customerName;
        obj.customCloudConnectivityValuesList =
          file.customCloudConnectivityValuesList;
        obj.customSAASConnectivityValuesList =
          file.customSAASConnectivityValuesList;
        document.getElementById("exportButton").style.right = "0%";
        this.setState({ customerName: file.customerName });
        updateDatabase();
      }).catch((error) => console.log(error));
  };

  readFileContent = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };
  // Converts the image into a data URI
  readImage = (file) => {
    const reader = new FileReader();
    var uploaded_image;
    reader.addEventListener("load", (event) => {
      uploaded_image = event.target.result;
      document.getElementById(
        "file"
      ).style.backgroundImage = `url(${uploaded_image})`;
      this.props.updateStorageItem('fileEncodedURL', uploaded_image);
      this.setState({ fileEncodedURL: uploaded_image });
    });


    reader.readAsDataURL(file);
  };

  onDrag = (event) => {
    // Event listener for dragging the image over the div
    event.stopPropagation();
    event.preventDefault();
    // Style the drag-and-drop as a "copy file" operation.
    event.dataTransfer.dropEffect = "copy";
  };

  handleButtonClick = () => {
    // Update the isDDOSProtection field to false when a button is clicked
    this.setState(prevState => ({
      apiObject: {
        ...prevState.apiObject,
        isDDOSProtection: false
      }
    }));
  }
  onDrop = (event) => {
    if (this.props.isForCustomerView) {
      return
    }
    // Event listener for dropping the image inside the div
    event.stopPropagation();
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    const addLogoBackground = document.getElementById("addLogoBackground");
    addLogoBackground.innerHTML = "";
    addLogoBackground.style.border = "none";
    addLogoBackground.style.background = "none";

    this.readImage(fileList[0]);
  };
  editField =
    (entity) =>
      ({ target }) => {
        const { value } = target;
        this.setState({ [entity]: value });
        this.props.updateStorageItem(entity, value);
      };

  toggleOpenSettings = () => {
    const { isSettingsOpen } = this.state;
    this.setState({ isSettingsOpen: !isSettingsOpen })
  }

  toggleOpenLinks = () => {
    const { isLinksOpen } = this.state;
    this.setState({ isLinksOpen: !isLinksOpen })
  }

  createLink = (a, fromButton = false) => {

    const { customerName } = this.state;
    // console.log("customerName",customerName)
    const { username, password, storage } = this.props;
    console.log("storage creation =>",storage)
    let updatedStorage = fromButton? {...storage, isDDOSProtection: false}:{...storage};
    let formData = new FormData();
    formData.append('slug', customerName && sha256(customerName));
    formData.append('customer_name', customerName && customerName);
    formData.append('json', JSON.stringify({...updatedStorage}));
  
    if (customerName) {
      axios({
        method: 'post',
        // for staging
        // url: 'http://aws5.dev.ipkt.io/api/create',
        // for demo
        url: '/api/create',
        data: formData,
        auth: {
          username: username,
          password: password
        },
      }).then((response) => {
        // const firstTab = window.open(`${sha256(customerName)}`);
        // if(a&&a=="onboard"){
          // firstTab.focus(); // Ensure focus on the first tab
  
          //const secondTab = window.open('https://share.hsforms.com/18HLaayWCQWiQpk4UUxj3zgedahg');
          // const secondTab = window.open('https://partners.insidepacket.com/enter-form');          
          // secondTab.focus();

        // }
         // Ensure focus on the second tab
      }).catch((error) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500
        });
      });
    }
  }
  

  importClick = () => {
    const { storage } = this.props;
    var text = JSON.stringify(storage);
    var filename = "output.txt";
    this.downloadFile(filename, text);
  };

  downloadFile = (filename, textInput) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8, " + encodeURIComponent(textInput)
    );
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.downloadLogoFile();
  };

  getDataCentersNumber = () => {
    const { dataCentersValueList } = this.props.storage;
    let number = 0;
    dataCentersValueList?.map((el) => {
      if (el > 0) {
        number++;
      }
    });

    return number

  }

  toggleCheckbox = (entity, value) => () => {
    // console.log("value",value)
    this.setState({ [entity]: !value });
    this.props.updateStorageItem(entity, !value);
  };

  downloadLogoFile = () => {
    const logoURL = document.getElementById("file").getAttribute("fileURL");
    const linkSource = `${logoURL}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "logo.jpg";
    downloadLink.click();
  };
  

  deleteLink = (slug) => () => {
    const { username, password } = this.props;

    let formData = new FormData();
    formData.append('slug', slug);

    axios({
      method: 'post',
      // for staging
      // url: `http://aws5.dev.ipkt.io/api/c/${slug}/delete`,
      // for demo
      url: `/api/c/${slug}/delete`,
      data: formData,
      auth: {
        username: username,
        password: password
      },
    }).then((response) => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Success',
        showConfirmButton: false,
        timer: 1500
      })
    }).catch((error) => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Error',
        showConfirmButton: false,
        timer: 1500
      })
    });
  }

  renderLinksModalWindow = () => {
    const { isLinksOpen } = this.state;
    const { isForCustomerView, fullDB } = this.props;
    if (isForCustomerView) {
      return
    }
    return (
      <Modal
        isOpen={isLinksOpen}
        style={settingsWindowStyle}
      >
        <div class='main__settings_wrapper'>
          <div class="main__settings_title">
            Links Management
          </div>
          <div class='main__settings_parameters'>
            <Scrollbars style={{ width: '20rem', height: 300 }}>
              {fullDB?.map((el) => {
                return (
                  <div class='main__links_item'>
                    <div class='main__links_columns'>
                    <div class='main__links_title'>Hash</div>
                      <input
                        type="text"
                        disabled
                        value={el.slug}
                      />
                      <div class='main__links_title'>Customer name</div>
                      <input
                        type="text"
                        disabled
                        value={el.customer_name}
                      />
                    </div>
                    <div onClick={this.deleteLink(el.slug)} class='main__delete_link_button'>Delete</div>
                  </div>

                )
              })}
            </Scrollbars>
          </div>
        </div>
        <div class='main__settings_buttons'>
          <button class='main__settings_cancel' onClick={this.toggleOpenLinks}>Close</button>
        </div>
      </Modal>
    )
  }

  renderSettingsModalWindow = () => {
    const { customerName, isSettingsOpen, isSave, is90sec , isDDOSProtection} = this.state;
    const { isForCustomerView, storage, getBranchOfficesNumber,  } = this.props;


    return (
      <Modal
        isOpen={isSettingsOpen}
        style={settingsWindowStyle}
      >
        <div class='main__settings_wrapper'>
          <div class="main__settings_title">
            Configuration
          </div>
          <div class='main__settings_parameters'>
            {!isForCustomerView && <div class='main__settings_item'>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="isSave"
                  checked={isSave}
                  onClick={this.toggleCheckbox("isSave", isSave)}
                />
                <label htmlFor="isSave">
                  <span>Save config</span>
                  <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="is90sec"
                  checked={is90sec}
                  onClick={this.toggleCheckbox("is90sec", is90sec)}
                />
                <label htmlFor="is90sec">
                  <span>90 seconds demo</span>
                  <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="isDDOSProtection"
                  checked={isDDOSProtection}
                  onClick={this.toggleCheckbox("isDDOSProtection", isDDOSProtection)}
                />
                <label htmlFor="isDDOSProtection">
                  <span>MSSP view</span>
                  <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </label>
              </div>
            </div>}
          </div>
        </div>
        <div class='main__settings_buttons'>
          <button class='main__settings_cancel' onClick={this.toggleOpenSettings}>Close</button>
          <button class='main__settings_create' onClick={()=>this.createLink()}>Create link</button>
        </div>
      </Modal>
    )
  }
  // handleToggleDDOSProtection = () => {
  //   this.props.toggleDDOSProtection(); // Call the callback function from props
  // };
  onboard = () => {
  
   let updatedStorage= this.props.setState(false)
   
    this.createLink("onboard", true)
    const { customerName } = this.state;
    const { username, password, storage } = this.props;
    updatedStorage = {...updatedStorage, isDDOSProtection: false};
    let formData = new FormData();
    formData.append('slug', customerName && sha256(customerName));
    formData.append('customer_name', customerName && customerName);
    formData.append('json', JSON.stringify({...updatedStorage}));

    // Get the current URL and append it to formData
    formData.append('OrigUrl', window.location.href);

    console.log('URL => ', window.location.href);

    if (customerName) {
   
      axios({
        method: 'post',
        // for staging
        // url: 'http://aws5.dev.ipkt.io/api/create',
        // for demo
        url: '/api/run',
        data: formData,
        auth: {
          username: username,
          password: password
        },
      }).then((response) => {
        // window.open(`https://share.hsforms.com/18HLaayWCQWiQpk4UUxj3zgedahg`)
        // window.open(`${sha256(customerName)}`)

       
      }).catch((error) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500
        })
      });
    }
    
  }

  render() {
    const { customerName, isSettingsOpen, isSave, is90sec , isDDOSProtection} = this.state;
    const { isForCustomerView, storage, getBranchOfficesNumber,  } = this.props;
    return (
      <>
        {this.renderSettingsModalWindow()}
        {this.renderLinksModalWindow()}

        <div class="main__head">
          <div class="main__head_item">
          {!this.props.isForCustomerView ?
      <input
      class="main__head_item_inp"
      type="text"
      placeholder="Customer name"
      value={customerName}
      onChange={this.editField("customerName")}
    />
      : this.props.storage.isDDOSProtection === true ?
       <input
       class="main__head_item_inp"
       type="text"
       placeholder="Customer name"
      //  value={customerName}
       onChange={this.editField("customerName")}
     />
      : 
      this.props.storage.isDDOSProtection === false ?
       <input
       class="main__head_item_inp"
       type="text"
       disabled
       placeholder="Customer name"
       value={customerName}
      //  onChange={this.editField("customerName")}
     />:
      ""}
            {/* <input
              class="main__head_item_inp"
              type="text"
              placeholder="Customer name"
              value={customerName}
              onChange={this.editField("customerName")}
            /> */}
          </div>
          <div class="main__head_action">
            {!isForCustomerView && <button class="import" onClick={this.toggleOpenSettings}>
              <img src="./img/settings1.svg" alt="" />
            </button>}
            {!isForCustomerView && <button class="import" onClick={this.toggleOpenLinks}>
              <img src="./img/link.svg" alt="" />
            </button>}
            {!isForCustomerView &&<button class="import" onClick={this.importClick}>
              {!(isForCustomerView && !this.props.storage?.isSave) && <img src="./img/import.svg" alt="" />}
            </button>}
           <span id="exportIcon" class="main__head_btn btn-primary btn-file" style={!isForCustomerView ?{}:{display:"none"}}>
              {!(isForCustomerView && !this.props.storage?.isSave) && <img src="./img/export.svg" alt="" />}
              <input id="exportButton" type="file" />
            </span>
          
          
          </div>
        </div>
        {(this.props?.carosal.length>0||this.props?.IframeValue)&&<button class="import" style={{width:"95%",textAlign:"end"}} onClick={()=> {
                   
                  
               this.props?.handleIframeValue(false)
                   this.props.carosalArray([]) 
           
               }}>
           <img src="./img/inp-res.svg" alt="" style={{width:'28px' ,cursor:"pointer"}}/>
            </button>}
        {this.props?.carosal.length>0&&<CustomModal carosal={this.props.carosal} currentSlide={this.props.currentSlide}  currentIndex={(e)=> {
                   
                   
                   
                   this.props.currentIndex(e) 
           
               }}/>}
                {this.props?.IframeValue==true&&
<iframe src="https://app.storylane.io/share/mejvkhhed0rj" style={{height:"700px",width:"91%",margin:'auto',display:'flex'}} title="Iframe Example"></iframe>}
        <div className="main__content" style={this.props?.carosal.length>0 ||this.props?.IframeValue?{display:"none"}:{}}>
          <div class="main__content_mid">
            <svg
              width="414"
              height="416"
              viewBox="0 0 414 416"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.1"
                d="M202.986 2.32895C205.468 0.888726 208.532 0.888725 211.014 2.32895L382.282 101.688C384.749 103.119 386.267 105.756 386.267 108.608V307.392C386.267 310.244 384.749 312.881 382.282 314.312L211.014 413.671C208.532 415.111 205.468 415.111 202.986 413.671L31.7183 314.312C29.2512 312.881 27.7327 310.244 27.7327 307.392V108.608C27.7327 105.756 29.2512 103.119 31.7183 101.688L202.986 2.32895Z"
                fill="#6E39DF"
              />
              <circle
                cx="207.5"
                cy="208.5"
                r="120.5"
                fill="white"
                stroke="#E2E2E8"
              />
            </svg>

            <div id="addLogoBackground" class="main__content_mid_in">
              <span>.jpg, .png, .svg</span>
              <span> {`< 200 MB`}</span>
            </div>
          </div>

          <div
            className="main__content_in"
            onDragOver={this.onDrag}
            onDrop={this.onDrop}
            id="image_drop_area"
          >
            <div id="file" fileURL={storage.fileEncodedURL}>
              &nbsp;
            </div>
            <p id="file_name"></p>

            <div className="val val_1">ZTNA</div>
            <div className="val val_2">SWG</div>
            <div id="DDOS_Protection" >
            {/* <div id="DDOS_Protection" className="val val_3"> */}
              {/* DDoS
              <span>Protection</span> */}
            </div>
            <div className="val val_4">FWaaS</div>
            <div className="main__content_item main__content_item_r">
              <div id="remoteUsers" className="value">
                {storage?.remoteUsers}
              </div>
              <div className="icon">
                <img src="./img/item-1.png" alt="" />
              </div>
              <div className="name">Remote</div>
            </div>
            <div className="main__content_item main__content_item_im">
              <div className="icon">
                <img src="./img/item-2.png" alt="" />
              </div>
              <div className="name">IT manager</div>
            </div>
            <div
              id="managed_SOC"
              className="main__content_item main__content_item_ms"
            >
              <div className="icon">
                <img src="./img/item-3.png" alt="" />
              </div>
              <div className="name">Managed SOC</div>
            </div>
            <div className="main__content_item main__content_item_bo">
              <div id="officesValue" className="value">
                {getBranchOfficesNumber()}
              </div>
              <div className="icon">
                <img src="./img/item-5.png" alt="" />
              </div>
              <div className="name">Branch Offices</div>
            </div>
            <div class="main__content_item main__content_item_cl_f">
              <div class="cloud">
                <img src="./img/cl-1.svg" />

                <div class="cloud__it" id="it1">
                  <img src="./img/logo-1.png" alt="" />
                </div>
                <div class="cloud__it" id="it2">
                  <img src="./img/logo-2.png" alt="" />
                </div>
                <div class="cloud__it" id="it3">
                  <img src="./img/logo-3.png" alt="" />
                </div>
                <div class="cloud__it" id="it4">
                  <img src="./img/logo-4.png" alt="" />
                </div>
                <div
                  id="customSAASConnectivityValuesList"
                  class="cloud__val"
                ></div>
              </div>
            </div>
            <div className="main__content_item main__content_item_cl_s">
              <div className="cloud">
                <div className="cloud__it" id="it5">
                  <img src="./img/logo-5.png" alt="" />
                </div>
                <div className="cloud__it" id="it6">
                  <img src="./img/logo-6.png" alt="" />
                </div>
                <div className="cloud__it" id="it7">
                  <img src="./img/logo-7.png" alt="" />
                </div>
                <div
                  id="customCloudConnectivityValuesList"
                  className="cloud__val"
                ></div>
                <img src="./img/cl-2.svg" />
              </div>
            </div>
            <div className="main__content_item main__content_item_dc">
              <div id="dataCentersValue" className="value">
                {this.getDataCentersNumber()}
              </div>
              <div className="icon">
                <img src="./img/item-4.png" alt="" />
              </div>
              <div className="name">DC/CoLo/EDGE</div>
            </div>
          </div>
          
        </div>
        {!this.props.isForCustomerView ?
        <div class="main__action_btn" style={this.props?.carosal.length>0 ||this.props?.IframeValue?{display:"none"}:{display:'flex',justifyContent:"center",marginTop:"20px"}}>
          <button onClick={()=>{
            this.onboard()}} style={this.state.isSettingsOpen||this.state.isLinksOpen?{zIndex:"0",cursor:"pointer"}:{zIndex:"99999999",cursor:"pointer"}}>Onboard yourself</button>
        </div>
       : this.props.storage.isDDOSProtection === true ?
       <div class="main__action_btn" style={this.props?.carosal.length>0 ||this.props?.IframeValue?{display:"none"}:{display:'flex',justifyContent:"center",marginTop:"20px"}}>
       <button onClick={() => {
  this.onboard();
  

}}  style={this.state.isSettingsOpen||this.state.isLinksOpen?{zIndex:"0",cursor:"pointer"}:{zIndex:"99999999",cursor:"pointer"}}>Onboard yourself</button>
     </div>:""}
        
      </>
    );
  }
}

export default Main_area_container;
