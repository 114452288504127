export default function priceCalculation({
  fromCodeFWaaS = 1,
  fromCodeIPS = 1,
  fromCodewebClassification = 1,
  fromCodeantiPhishing = 1,
  fromCodemultiCloud = 1,
  fromCodesecuredWebFiltering = 1,
  fromCodemanagedSOC = 1,
  fromCodeDDosProtection = 0,
  fromCodebranch1 = 500,
  fromCodebranch2 = 0,
  fromCodebranch3 = 0,
  fromCodebranch4 = 0,
  fromCodebranch5 = 0,
  fromCodeDc1 = 500,
  fromCodeDc2 = 0,
  fromCodeDc3 = 0,
  fromCodeDc4 = 0,
  fromCodeDc5 = 0,
  fromCodeRemoteUsers = 50,
  fromCodeBox = 1,
  fromCodeSalesforce = 1,
  fromCodeOffice = 1,
  fromCodeCustomSAAS1 = 0,
  fromCodeCustomSAAS2 = 0,
  fromCodeCustomSAAS3 = 0,
  fromCodeCustomSAAS4 = 0,
  fromCodeAWS = 1,
  fromCodeAzure = 1,
  fromCodeGCP = 1,
  fromCodeCustomCloud1 = 0,
  fromCodeCustomCloud2 = 0,
  fromCodeCustomCloud3 = 0,
  fromCodeCustomCloud4 = 0,
}) {
  // variables declaration block - for delevopers
  // security services block
  let FWaaS = fromCodeFWaaS,
    IPS = fromCodeIPS,
    webClassification = fromCodewebClassification,
    antiPhishing = fromCodeantiPhishing,
    multiCloud = fromCodemultiCloud,
    securedWebFiltering = fromCodesecuredWebFiltering,
    managedSOC = fromCodemanagedSOC,
    DDosProtection = fromCodeDDosProtection;
  // branch connectivity block
  let branch1 = fromCodebranch1,
    branch2 = fromCodebranch2,
    branch3 = fromCodebranch3 || 0,
    branch4 = fromCodebranch4 || 0,
    branch5 = fromCodebranch5 || 0;
  // data centers block
  let dc1 = fromCodeDc1,
    dc2 = fromCodeDc2,
    dc3 = fromCodeDc3 || 0,
    dc4 = fromCodeDc4 || 0,
    dc5 = fromCodeDc5 || 0;
  // work from home block
  let remoteUsers = fromCodeRemoteUsers;
  // saas connectivity block
  let box = fromCodeBox,
    salesforce = fromCodeSalesforce,
    office = fromCodeOffice,
    customSAAS1 = fromCodeCustomSAAS1 || 0,
    customSAAS2 = fromCodeCustomSAAS2 || 0,
    customSAAS3 = fromCodeCustomSAAS3 || 0,
    customSAAS4 = fromCodeCustomSAAS4 || 0;
  // public connectivity block
  let AWS = fromCodeAWS,
    azure = fromCodeAzure,
    GCP = fromCodeGCP,
    customCloud1 = fromCodeCustomCloud1 || 0,
    customCloud2 = fromCodeCustomCloud2 || 0,
    customCloud3 = fromCodeCustomCloud3 || 0,
    customCloud4 = fromCodeCustomCloud4 || 0;
  // final values block
  let numberOfTenants1, numberOfTenants2, numberOfTenants3;
  let totalThroughput1 = 10,
    totalThroughput2 = 50,
    totalThroughput3 = 100;
  let pricePerTenant1 = 1000,
    pricePerTenant2 = 1000,
    pricePerTenant3 = 1000;
  let saving1, saving2, saving3;
  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------- //

  // formulas block

  // helper variables
  let isBranch1 = branch1 > 0;
  let isBranch2 = branch2 > 0;
  let isBranch3 = branch3 > 0;
  let isBranch4 = branch4 > 0;
  let isBranch5 = branch5 > 0;

  let isdc1 = dc1 > 0;
  let isdc2 = dc2 > 0;
  let isdc3 = dc3 > 0;
  let isdc4 = dc4 > 0;
  let isdc5 = dc5 > 0;

  // all checkboxes can have the value of 0 or 1. All other inputs can have any numeric value

  // full list of parameters, ordered: FWaaS, IPS, webClassification, antiPhishing, multiCloud, securedWebFiltering, managedSOC, DDosProtection, branch1, branch2, branch3, branch4,
  // branch5, dc1, dc2, dc3, dc4, dc5, remoteUsers, box, salesforce, office, customSAAS1, customSAAS2, customSAAS3, customSAAS4, AWS, azure, GCP, customCloud1, customCloud2,
  // customCloud3, customCloud4

  // if you want to change number of tenants you can do it in this block. The numbers 1, 2, 3 reflect the table column number
  numberOfTenants1 = 1; //220
  numberOfTenants2 = 200; //167
  numberOfTenants3 = 500; //154

  // if you want to change total throughput you can do it in this block. The numbers 1, 2, 3 reflect the table column number. Parameters list is reflected above
  // you can use parameters or you can use already declared values like in totalThroughput2 and totalThroughput3
  totalThroughput1 =
    (500 +
      AWS * 50 +
      azure * 50 +
      GCP * 50 +
      (branch1 + branch2 + branch3 + branch4 + branch5) +
      (dc1 + dc2 + dc3 + dc4 + dc5)) *
    numberOfTenants1;
  totalThroughput2 =
    (500 +
      AWS * 50 +
      azure * 50 +
      GCP * 50 +
      (branch1 + branch2 + branch3 + branch4 + branch5) +
      (dc1 + dc2 + dc3 + dc4 + dc5)) *
    numberOfTenants2;
  totalThroughput3 =
    (500 +
      AWS * 50 +
      azure * 50 +
      GCP * 50 +
      (branch1 + branch2 + branch3 + branch4 + branch5) +
      (dc1 + dc2 + dc3 + dc4 + dc5)) *
    numberOfTenants3;

  // if you want to change price per tenant you can do it in this block. The numbers 1, 2, 3 reflect the table column number. Parameters list is reflected above
  pricePerTenant1 =
    220 +
    AWS * 50 +
    azure * 50 +
    GCP * 50 +
    antiPhishing * 20 +
    securedWebFiltering * 20 +
    managedSOC * 50 +
    (isBranch1 + isBranch2 + isBranch3 + isBranch4 + isBranch5) * 10 +
    (isdc1 + isdc2 + isdc3 + isdc4 + isdc5) * 14 +
    remoteUsers * 6;
  pricePerTenant2 =
    167 +
    AWS * 50 +
    azure * 50 +
    GCP * 50 +
    antiPhishing * 18 +
    securedWebFiltering * 18 +
    managedSOC * 40 +
    (isBranch1 + isBranch2 + isBranch3 + isBranch4 + isBranch5) * 10 +
    (isdc1 + isdc2 + isdc3 + isdc4 + isdc5) * 14 +
    remoteUsers * 6;
  pricePerTenant3 =
    154 +
    AWS * 50 +
    azure * 50 +
    GCP * 50 +
    antiPhishing * 15 +
    securedWebFiltering * 15 +
    managedSOC * 30 +
    (isBranch1 + isBranch2 + isBranch3 + isBranch4 + isBranch5) * 10 +
    (isdc1 + isdc2 + isdc3 + isdc4 + isdc5) * 10 +
    remoteUsers * 5;

  // if you want to change saving from competition you can do it in this block. The numbers 1, 2, 3 reflect the table column number. Parameters list is reflected above
  saving1 = 50;
  saving2 = 62;
  saving3 = 65;

  return {
    numberOfTenants1,
    numberOfTenants2,
    numberOfTenants3,
    pricePerTenant1,
    pricePerTenant2,
    pricePerTenant3,
    totalThroughput1,
    totalThroughput2,
    totalThroughput3,
    saving1,
    saving2,
    saving3,
  };
}
