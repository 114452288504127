import React, { Component } from 'react';

class CustomModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          currentIndex: 0,
          items: props.carosal, // Array of items to display in the carousel
        };
      }

  

// componentDidMount() {
// const link = document.createElement('link');
// link.rel = 'stylesheet';
// link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css';
// document.head.appendChild(link);
//   }
 

prevSlide = () => {
   
      this.props.currentIndex
      (  (this.props.currentSlide - 1 + this.props.carosal.length) %
        this.props.carosal.length)

  };

  nextSlide = () => {
    
    this.props.currentIndex((this.props.currentSlide + 1) % this.props.carosal.length)

  };
  goToSlide = (index) => {
    this.props.currentIndex( index );
  };
  render() {
    console.log(this.props.carosal,this.state.items,"vbn")
   

    return (
  <div style={{backgroundColor:"black"}} className='header_carosal'><div className='sub_header_carosal' style={{backgroundColor:"#141414",width:"95%",margin:"0 auto"}}> <div className="carousel">
  <button className="arrow prev" onClick={this.prevSlide}>
  <img src="./img/Feedbin-Icon-left-arrow.svg.png" alt="Previous" width="40" height="40" className='carosalIcon'/>
  </button>
  <div className="carousel-content">
    
    <img src={this.props.carosal[this.props.currentSlide]} alt={this.props.carosal[this.props.currentSlide]} className='carosalImg'/>

  </div>
  <button className="arrow next" onClick={this.nextSlide} >
  <img src="./img/leftarroww.png" alt="Next" width="40" height="40" className='carosalIcon'/>
  </button>
  <div className="pagination">
    {this.props.carosal.map((item, index) => (
      <span
        key={index}
        className={`pagination-dot ${
          index === this.props.currentSlide ? "active" : ""
        }`}
        onClick={() => this.goToSlide(index)}
      ></span>
    ))}
  </div>
</div></div></div>      

        
       
  



 
    );
  }
}

export default CustomModal;
