import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

const grayedIcons = ["it2", "it3", "it4", "it5", "it6", "it7"];
const disappearIcons = ["managed_SOC", "DDOS_Protection"];
const imagesData = {
  Security_Services_carousel_files:['./img/Slide13.jpg'],
  FWass_carousel_files:[],
  IPS_carousel_files:[],
  Web_carousel_files:[],
  Anti_phish_carousel_files:[],
  Multi_cloud_connectivity_carousel_files:[],
  secure_web_filtering_carousel_files:[],
  manage_Soc_carousel_files:[],
  DDos_protection_carousel_files:[],
  work_from_home_carousel_files:[],
  Remote_users_carousel_files:[],
  Branch_connectivity_carousel_files:[],
  Branch_connectivity:[
    [],
    [],
  ],
  public_cloud_connectivity_carousel_files:[],
  aws_carousel_files:[],
  azure_carousel_files:[],
  gcp_carousel_files:[],
  Sass_connectivity_carousel_files:[],
  box_connectivity_carousel_files:[],
  saleforce_connectivity_carousel_files:[],
  office365_connectivity_carousel_files:[],
  Data_center_carousel_files:[],
  data_center:[
    [],
    [],
  ] 
};


const e = React.createElement;

class Aside_scrollbar_container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchValuesList: [ 0],
      dataCentersValueList: [0, 0],
      remoteUsers: 20,
      isFWaaS: true,
      isIPS: true,
      isWebClassification: true,
      isAntiPhishing: false,
      isMultiCloud: true,
      isSecuredWebFiltering: false,
      isManagedSOC: false,
      isAWS: true,
      isAzure: true,
      isGCP: true,
      customCloudConnectivityValuesList: [],
      customSAASConnectivityValuesList: [],
      exportButtonIsPercent: false,
      isDDOSProtection: false,
      isBOX: true,
      isOffice: true,
      isSalesforce: true,
    };
  }

  componentDidMount() {
    // if manager page, not customer
    // this.numofBranches();
    if (!this.props.isForCustomerView) {
      let keys = Object.keys(this.state);
      for (let key of keys) {
        this.props.updateStorageItem(key, this.state[key]);
      }

      this.hideIconIfNotChecked(
        "managed_SOC",
        false)
        ;
      this.hideIconIfNotChecked(
        "DDOS_Protection",
        false)
        ;
    }
    // if customer page
    else {
      this.updateFullStateWIthObject();
    }


    this.timer = setInterval(() => {
      const exportButton = document.getElementById("exportButton");
      exportButton?.addEventListener("change", this.updateFullStateWIthObject);
    }, 500);
  }
 
  updateFullStateWIthObject = (event) => {
    const timer = setTimeout(() => {
      const obj = this.props.storage;
      this.setState({
        branchValuesList: obj.branchValuesList,
        dataCentersValueList: obj.dataCentersValueList,
        isAWS: obj.isAWS,
        isAntiPhishing: obj.isAntiPhishing,
        isAzure: obj.isAzure,
        isFWaaS: obj.isFWaaS,
        isGCP: obj.isGCP,
        isIPS: obj.isIPS,
        isManagedSOC: obj.isManagedSOC,
        isMultiCloud: obj.isMultiCloud,
        isBOX: obj.isBOX,
        isSalesforce: obj.isSalesforce,
        isOffice: obj.isOffice,
        isSecuredWebFiltering: obj.isSecuredWebFiltering,
        isWebClassification: obj.isWebClassification,
        isDDOSProtection: obj.isDDOSProtection,
        remoteUsers: obj.remoteUsers,
        customCloudConnectivityValuesList: this.getJSONValue(
          obj.customCloudConnectivityValuesList
        ),
        customSAASConnectivityValuesList: this.getJSONValue(
          obj.customSAASConnectivityValuesList
        ),
      });
      this.changeMainAreaNumericValue("remoteUsers", obj.remoteUsers);
      this.colorIconConnectedWithCheckbox("it2", obj.isBOX);
      this.colorIconConnectedWithCheckbox(
        "it3",
        obj.isSalesforce)
        ;
      this.colorIconConnectedWithCheckbox("it4", obj.isOffice);
      this.colorIconConnectedWithCheckbox("it5", obj.isAWS);
      this.colorIconConnectedWithCheckbox("it6", obj.isGCP);
      this.colorIconConnectedWithCheckbox("it7", obj.isAzure);
      this.hideIconIfNotChecked(
        "managed_SOC",
        obj.isManagedSOC)
        ;
      this.hideIconIfNotChecked(
        "DDOS_Protection",
        obj.isDDOSProtection)
        ;
      if (obj.isDDOSProtection) {
        document.documentElement.style.setProperty(
          "--arrowVisibility",
          "visible"
        );
      } else {
        document.documentElement.style.setProperty(
          "--arrowVisibility",
          "hidden"
        );
      }

      // for some reason position of the button jumps back and forth. This block will make it sticky
      if (this.state.exportButtonIsPercent) {
        document.getElementById("exportButton").style.right = "0%";
      } else {
        document.getElementById("exportButton").style.right = "0";
      }
    }, 500);
    return () => clearTimeout(timer);
  };

  getJSONValue = (val) => {
    return eval("(function(){return " + val + ";})()");
  };
  
  changeMainAreaNumericValue(id, number) {
 
      // const { branchValuesList } = this.state;
      // console.log("branchValuesList11",branchValuesList)
      
    let currentNumber = number || 0;
    const remote = document.getElementById(id);
    remote.innerHTML = `<div id=${id} ">${currentNumber}</div>`;
  }

  colorIconConnectedWithCheckbox(id, condition) {
    const grayedOutStyle =
      "-webkit-filter: grayscale(1);filter: gray;filter: grayscale(1);";
    const element = document.getElementById(id);
    if (!condition) {
      element.setAttribute("style", grayedOutStyle);
    } else {
      element.removeAttribute("style", grayedOutStyle);
    }
  }

  hideIconIfNotChecked(id, condition) {
    const elem = document.getElementById(id);
    if (!condition) {
      elem.style.visibility = "hidden";
    } else {
      elem.style.visibility = "visible";
    }
  }

  addBranch = () => {
    const { branchValuesList } = this.state;
    if (branchValuesList?.length < 5) {
      branchValuesList.push(0);
    }
    this.setState({ branchValuesList });
    this.props.updateStorageItem("branchValuesList", branchValuesList);
    this.props.updateDatabase();
  };

  addDataCenter = () => {
    const { dataCentersValueList } = this.state;
    if (dataCentersValueList?.length < 5) {
      dataCentersValueList.push(0);
    }
    this.setState({ dataCentersValueList });
    this.props.updateStorageItem("dataCentersValueList", dataCentersValueList);
    this.props.updateDatabase();
  };

  validateOnlyNumbers = (value) => {
    return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
  }


  changeBandwidthValue =
    (i, isForBranch) =>
      ({ target }) => {
        // console.log("check => ",i, isForBranch, target.value, remoteUsers)
        // this.props?.remoteuser(20+(+target.value))
        // this.changeMainAreaNumericValue("remoteUsers", 20+(+target.value));
        // this.setState({ ['remoteUsers']: 20+(+target.value) });
        // this.props.updateStorageItem("remoteUsers", 20+(+target.value));
        // this.props.updateDatabase();

        const { branchValuesList, dataCentersValueList } = this.state;
        const { value } = target;
        if (isForBranch) {
          const tempValuesList = _.cloneDeep(branchValuesList);
          tempValuesList[i] = this.validateOnlyNumbers(value);
          this.setState({ branchValuesList: tempValuesList });
          this.props.updateStorageItem("branchValuesList", tempValuesList);
        } else {
          const tempValuesList = _.cloneDeep(dataCentersValueList);
          tempValuesList[i] = this.validateOnlyNumbers(value);
          this.setState({ dataCentersValueList: tempValuesList });
          this.props.updateStorageItem("dataCentersValueList", tempValuesList);
        }
        this.props.updateDatabase();
      };
  

  editField =
    (entity) =>
    
      ({ target }) => {
  
        const { value } = target;
        if (entity === "remoteUsers") {
          this.props?.remoteuser(+value)
          
          this.changeMainAreaNumericValue("remoteUsers", +value);
        }
        this.setState({ [entity]: +value });
        this.props.updateStorageItem(entity, +value);
        this.props.updateDatabase();

      };


  toggleCheckbox = (entity, value, id) => () => {
    this.setState({ [entity]: !value });

    if (_.indexOf(grayedIcons, id) !== -1) {
      this.colorIconConnectedWithCheckbox(id, !value);
    }
    if (_.indexOf(disappearIcons, id) !== -1) {
      this.hideIconIfNotChecked(id, !value);
    }

    if (entity === "isDDOSProtection") {
      if (!value) {
        document.documentElement.style.setProperty(
          "--arrowVisibility",
          "visible"
        );
      } else {
        document.documentElement.style.setProperty(
          "--arrowVisibility",
          "hidden"
        );
      }
    }
    this.props.updateStorageItem(entity, !value);
    this.props.updateDatabase();
  };

  getBranchesList = () => {
    // console.log(this.numofBranches());
    const { branchValuesList } = this.state;
    console.log("branchValuesList11",branchValuesList)
    return branchValuesList?.map((el, i) => {
      return (
        <div className="aside__part_item input">
          <span>{`number of branches`}</span>
          {/* <span>{`Branch ${i + 1}`}</span> */}
          <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.Branch_connectivity[i].length>0&&<span style={{marginRight:"19px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px",cursor:"pointer"}} onClick={()=>{
                    this.handlerClick(imagesData?.Branch_connectivity[i])}}>i</span>}
                  <div >
                  <input
            onChange={this.changeBandwidthValue(i, true)}
            type="text"
            value={el}
          />
                  </div>
                  </div>
         
        </div>
      );
    });
  };
  getDataCentersList = () => {
    const { dataCentersValueList } = this.state;
    return dataCentersValueList?.map((el, i) => {
      return (
        <div className="aside__part_item input">
          <span>{`Data center ${i + 1}`}</span>
          <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.data_center[i].length>0&&<span style={{marginRight:"19px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px",cursor:"pointer"}} onClick={()=>{
                    this.handlerClick(imagesData?.data_center[i])}}>i</span>}
                  <div >
                  <input
            onChange={this.changeBandwidthValue(i, false)}
            type="text"
            value={el}
          />
                  </div>
                  </div>
         
        </div>
      );
    });
  };

  changeOtherCloudNumber = (id, list) => {
    let checkedNum = 0;
    const element = document.getElementById(id);
    list?.map((el) => {
      if (el.value === true) {
        checkedNum++;
      }
    });
    if (checkedNum === 0) {
      element.style.visibility = "hidden";
    } else {
      element.style.visibility = "visible";
      element.innerHTML = `+${checkedNum}`;
    }
  };

  toggleCustomCheckbox = (value, index, array, entity) => () => {
    const tempList = array;
    tempList[index].value = value;
    this.setState({ [array]: tempList });
    this.props.updateStorageItem(`${entity}`, JSON.stringify(tempList));
    this.changeOtherCloudNumber(`${entity}`, tempList);
    this.props.updateDatabase();
  };

  changeCustomName =
    (index, array, entity) =>
      ({ target }) => {
        const tempList = array;
        tempList[index].name = target.value;
        this.setState({ [array]: tempList });
        this.props.updateStorageItem(`${entity}`, JSON.stringify(tempList));
        this.props.updateDatabase();
      };

  addCustomConnectivity = (array, entity) => () => {
    const tempList = array || [];
    if (tempList?.length < 4) {
      tempList.push({ name: "Custom", value: true });
    }
    const element = document.getElementById(`${entity}`);
    element.innerHTML = `+${tempList?.length}`;
    this.setState({ [entity]: tempList });
    this.props.updateStorageItem(`${entity}`, JSON.stringify(tempList));
    this.props.updateDatabase();
  };

  getCustomConnectivityList = (array, entity) => {
    return array?.map((el, index) => {
      return (
        <div className="aside__part_item checkbox">
          <input type="checkbox" checked={el.value} />
          <label>
            <div class="custom__inp">
              <input
                type="text"
                value={el.name}
                onChange={this.changeCustomName(index, array, entity)}
              />
            </div>
            <div
              className="icon"
              onClick={this.toggleCustomCheckbox(
                !el.value,
                index,
                array,
                entity
              )}
            >
              <div className="icon__in">
                <svg
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </label>
        </div>
      );
    });
  };
  
  handlerClick=(e)=>{
    this.props?.handleIframeValue(false)
   this.props.carosal(e)
      }
  handlerIframe=()=>{
            this.props.IframeValue(true)
          }


        
  render() {
    const {
      remoteUsers,
      isAntiPhishing,
      isMultiCloud,
      isSecuredWebFiltering,
      isManagedSOC,
      isAWS,
      isAzure,
      isGCP,
      isDDOSProtection,
      isBOX,
      isOffice,
      isSalesforce,
      customCloudConnectivityValuesList,
      customSAASConnectivityValuesList,
    } = this.state;
    return (
      <>
           <div className="aside__in scroll">
          <div className="aside__part">
          
            <div style={{display:'flex',alignItems:"center",justifyContent:'space-between'}}>
            <h2 className="aside__part_title">Security Services</h2>

  {imagesData?.Security_Services_carousel_files.length>0&&  <img src="./img/new_info_icon.svg" style={{marginRight:"98px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
  this.handlerIframe()}}/> }
</div>
            <div className="aside__part_list">
              <div className="aside__part_item checkbox">
                <input type="checkbox" id="ch1"  />
                <label htmlFor="ch1" >
                  <span >FWaaS</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.FWass_carousel_files.length>0&&
                     <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                      this.handlerClick(imagesData?.FWass_carousel_files)}}/> 
                    }
                       {/* {imagesData?.FWass_carousel_files.length>0&&
                    <span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.FWass_carousel_files)}}>i</span>} */}
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input type="checkbox" id="ch2"  />
                <label htmlFor="ch2" >
                  <span>IPS</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.IPS_carousel_files.length>0&&   
                     <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                      this.handlerClick(imagesData?.IPS_carousel_files)}}/>
                    }
                    {/* {imagesData?.IPS_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.IPS_carousel_files)}}>i</span>} */}
                    
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input type="checkbox" id="ch3"  />
                <label htmlFor="ch3" >
                  <span>Web classification</span>
                
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.Web_carousel_files.length>0&& 
                      <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                        this.handlerClick(imagesData?.Web_carousel_files)}}/>
                    }
                    {/* {imagesData?.Web_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.Web_carousel_files)}}>i</span>} */}
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch4"
                  checked={isAntiPhishing}
                  onClick={this.toggleCheckbox(
                    "isAntiPhishing",
                    isAntiPhishing
                  )}
                />
                <label htmlFor="ch4"  >
                  <span>Anti phishing</span>
                  
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.Anti_phish_carousel_files.length>0&&
                     <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                      this.handlerClick(imagesData?.Anti_phish_carousel_files)}}/>
                    }
                    {/* {imagesData?.Anti_phish_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.Anti_phish_carousel_files)}}>i</span>} */}
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                  
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch5"
                  checked={isMultiCloud}
                  onClick={this.toggleCheckbox("isMultiCloud", isMultiCloud)}
                />
                
                <label htmlFor="ch5">
                  <span>Multi-Cloud connectivity</span>
                  
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.Multi_cloud_connectivity_carousel_files.length>0&&
                       <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                        this.handlerClick(imagesData?.Multi_cloud_connectivity_carousel_files)}}/>
                    }
                      {/* {imagesData?.Multi_cloud_connectivity_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.Multi_cloud_connectivity_carousel_files)}}>i</span>} */}
                    
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch6"
                  checked={isSecuredWebFiltering}
                  onClick={this.toggleCheckbox(
                    "isSecuredWebFiltering",
                    isSecuredWebFiltering
                  )}
                />
                <label htmlFor="ch6">
                  <span>Secured Web Filtering</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.secure_web_filtering_carousel_files.length>0&&
                    <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                      this.handlerClick(imagesData?.secure_web_filtering_carousel_files)}}/>
                    }
                     {/* {imagesData?.secure_web_filtering_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.secure_web_filtering_carousel_files)}}>i</span>} */}
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch7"
                  checked={isManagedSOC}
                  onClick={this.toggleCheckbox(
                    "isManagedSOC",
                    isManagedSOC,
                    "managed_SOC"
                  )}
                />
                <label htmlFor="ch7">
                  <span>Managed 24/7 SOC and Analytics</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.manage_Soc_carousel_files.length>0&&
                       <img src="./img/new_info_icon.svg" style={{marginRight:"81px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
                        this.handlerClick(imagesData?.manage_Soc_carousel_files)}}/>
                    }
                     {/* {imagesData?.manage_Soc_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.manage_Soc_carousel_files)}}>i</span>} */}
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              {/* <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="chddos"
                  checked={isDDOSProtection}
                  onClick={this.toggleCheckbox(
                    "isDDOSProtection",
                    isDDOSProtection,
                    "DDOS_Protection"
                  )}
                />
                <label htmlFor="chddos">
                  <span>DDoS Protection</span>
                  <div style={{display:'flex',alignItems:"center"}}>
  

                    {imagesData?.DDos_protection_carousel_files.length>0&&<span style={{marginRight:"41px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.DDos_protection_carousel_files)}}>i</span>}
                    
                  <div className="icon">
        
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div> */}
            </div>
          </div>
          <div className="aside__part">
           
            <div style={{display:'flex',alignItems:"center",justifyContent:'space-between'}}>
            <h2 className="aside__part_title">Work from home</h2>

  {imagesData?.work_from_home_carousel_files.length>0&&
   <img src="./img/new_info_icon.svg" style={{marginRight:"98px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
    this.handlerClick(imagesData?.work_from_home_carousel_files)}}/>
  }
  
  {/* {imagesData?.work_from_home_carousel_files.length>0&&<span style={{marginRight:"98px",border:"1px solid black",borderRadius:"15px",padding:"0px 7px",cursor:'pointer'}} onClick={()=>{
  this.handlerClick(imagesData?.work_from_home_carousel_files)}}>i</span>} */}
</div>
            <div className="aside__part_list">
              <div className="aside__part_item input">
                <span>Remote users</span>
                <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.Remote_users_carousel_files.length>0&&<img src="./img/new_info_icon.svg" style={{marginRight:"19px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
    this.handlerClick(imagesData?.Remote_users_carousel_files)}}/>}
                       {/* {imagesData?.Remote_users_carousel_files.length>0&&<span style={{marginRight:"19px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px",cursor:'pointer'}} onClick={()=>{
                    this.handlerClick(imagesData?.Remote_users_carousel_files)}}>i</span>} */}
                  <div >
        
                  <input
                  onChange={this.editField("remoteUsers")}
                  type="text"
                  value={remoteUsers}
                  />
                 
                  </div>
                  </div>
               
              </div>
            </div>
          </div>
          <div className="aside__part">
          
            <div ><h2 className="aside__part_title" >
              Branch connectivity (Max Bandwidth, Mbps)
            </h2>
       </div>
        
            <div style={{textAlign:'right'}}>
       {imagesData?.Branch_connectivity_carousel_files.length>0&&  <div style={{display:"inline-block",textAlign:"center",border:"1px solid black",borderRadius:"15px",cursor:'pointer',marginBottom:"6px",marginRight:"99px",width:"20px"}} onClick={()=>this.handlerClick(imagesData?.Branch_connectivity_carousel_files)}>i</div>}
       {/* {imagesData?.Branch_connectivity_carousel_files.length>0&&  <div style={{display:"inline-block",textAlign:"center",border:"1px solid black",borderRadius:"15px",cursor:'pointer',marginBottom:"6px",marginRight:"99px",width:"20px"}} onClick={()=>this.handlerClick(imagesData?.Branch_connectivity_carousel_files)}>i</div>} */}
      
       </div>
            
            <div className="aside__part_list" id="actionList">
              {this.getBranchesList()}
            </div>
            {/* <button
              onClick={this.addBranch}
              className="aside__part_add"
              type="button"
            >
              +
            </button> */}
          </div>
          <div className="aside__part">
            <div style={{display:'flex',justifyContent:"space-between",alignItems:'center'}}>
              
              <h2 className="aside__part_title">Public cloud connectivity</h2>
              {imagesData?.public_cloud_connectivity_carousel_files.length>0&&
             <img src="./img/new_info_icon.svg" style={{marginRight:"98px",cursor:'pointer', marginBottom:"5px", height:'22px', width:'22px'}} onClick={()=>{
              this.handlerClick(imagesData?.public_cloud_connectivity_carousel_files)}}/> }
             
              {/* {imagesData?.public_cloud_connectivity_carousel_files.length>0&&<div style={{marginRight:"98px",border:"1px solid black",borderRadius:"15px",padding:"0px 7px",cursor:'pointer'}} onClick={()=>this.handlerClick(imagesData?.public_cloud_connectivity_carousel_files)}>i</div>} */}
              
              </div>
            
         
            <div className="aside__part_list">
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch8"
                  checked={isAWS}
                  onClick={this.toggleCheckbox("isAWS", isAWS, "it5")}
                />
                <label htmlFor="ch8">
                  <span>AWS</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.aws_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.aws_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch9"
                  checked={isAzure}
                  onClick={this.toggleCheckbox("isAzure", isAzure, "it7")}
                />
                <label htmlFor="ch9">
                  <span>Azure</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.azure_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.azure_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="ch10"
                  checked={isGCP}
                  onClick={this.toggleCheckbox("isGCP", isGCP, "it6")}
                />
                <label htmlFor="ch10">
                  <span>GCP</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.gcp_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.gcp_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              {this.getCustomConnectivityList(
                customCloudConnectivityValuesList,
                "customCloudConnectivityValuesList"
              )}
            </div>
            <button
              onClick={this.addCustomConnectivity(
                customCloudConnectivityValuesList,
                "customCloudConnectivityValuesList"
              )}
              className="aside__data_center_add"
              type="button"
            >
              +
            </button>
          </div>
          <div className="aside__part">
          <div style={{display:'flex',alignItems:"center",justifyContent:'space-between'}}>
            <h2 className="aside__part_title">SAAS connectivity</h2>

  {imagesData?.Sass_connectivity_carousel_files.length>0&&<span style={{marginRight:"98px",border:"1px solid black",borderRadius:"15px",padding:"0px 7px",cursor:'pointer'}} onClick={()=>{
  this.handlerClick(imagesData?.Sass_connectivity_carousel_files)}}>i</span>}
  

</div>
      
            <div className="aside__part_list">
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="chBox"
                  checked={isBOX}
                  onClick={this.toggleCheckbox("isBOX", isBOX, "it2")}
                />
                <label htmlFor="chBox">
                  <span>box</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.box_connectivity_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.box_connectivity_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="chSales"
                  checked={isSalesforce}
                  onClick={this.toggleCheckbox(
                    "isSalesforce",
                    isSalesforce,
                    "it3"
                  )}
                />
                <label htmlFor="chSales">
                  <span>Salesforce</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.saleforce_connectivity_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.saleforce_connectivity_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              <div className="aside__part_item checkbox">
                <input
                  type="checkbox"
                  id="chOffice"
                  checked={isOffice}
                  onClick={this.toggleCheckbox("isOffice", isOffice, "it4")}
                />
                <label htmlFor="chOffice">
                  <span>Office 365</span>
                  <div style={{display:'flex',alignItems:"center"}}>
                    {imagesData?.office365_connectivity_carousel_files.length>0&&<span style={{marginRight:"81px",border:"1px solid ",borderRadius:"15px",padding:"0px 7px"}} onClick={()=>{
                    this.handlerClick(imagesData?.office365_connectivity_carousel_files)}}>i</span>}
                    
                    <div className="icon">
                    <div className="icon__in">
                      <svg
                        width="11"
                        height="8"
                        viewBox="0 0 11 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  </div>
                </label>
              </div>
              {this.getCustomConnectivityList(
                customSAASConnectivityValuesList,
                "customSAASConnectivityValuesList"
              )}
            </div>
            <button
              onClick={this.addCustomConnectivity(
                customSAASConnectivityValuesList,
                "customSAASConnectivityValuesList"
              )}
              className="aside__data_center_add"
              type="button"
            >
              +
            </button>
          </div>
          <div className="aside__part">
      
            <div ><h2 className="aside__part_title" style={{marginRight:"10px"}}>
            Data Center / CoLo / Edge (Max Bandwidth, Mbps)
            </h2>
       </div>
       <div style={{textAlign:'right'}}>
       { imagesData?.Data_center_carousel_files.length>0&& <div style={{display:"inline-block",textAlign:"center",border:"1px solid black",borderRadius:"15px",cursor:'pointer',marginBottom:"6px",marginRight:"99px",width:"20px"}} onClick={()=>this.handlerClick(imagesData?.Data_center_carousel_files)}>i</div>}
       </div>
      
    
           
           
            <div className="aside__part_list" id="data_center_list">
              {this.getDataCentersList()}
            </div>
            <button
              onClick={this.addDataCenter}
              className="aside__data_center_add"
              type="button"
            >
              +
            </button>
          </div>
          
         
         
        </div>

        <div className="aside__action">
          <button className="aside__action_btn reset">Reset all</button>
        </div>
      </>
    );
  }
}
export default Aside_scrollbar_container;



