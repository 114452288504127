import Aside_scrollbar_container from "./aside_scrollbar_container";
import Main_area_container from "./main_area_container";
import Main_action_container from "./main_action_container";
import Main_bottom_line from "./main_bottom_line";
import { sha256 } from 'js-sha256';

import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import Swal from "sweetalert2";
import getInsidepacketLogo from "./helpers/getInsidepacketLogo";
import CustomModal from "./carosal_modal";

const axios = require('axios').default;
axios.defaults.baseURL = 'https://mssp-calc.insidepacket.com/';
const YOUR_NETWORK_PAGE_REDIRECT_PATHNAME = '/yourNetworkPageRedirect';
const YOUR_NETWORK_PAGE_NO_REDIRECT_PATHNAME = '/yourNetworkPageNoRedirect';



class Wrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storage: {},
      isAuth: false,
      username: undefined,
      password: undefined,
      isPricingShown: false,
      isForCustomerView: false,
      currentIp: undefined,
      fullDB: undefined,
      carosal:[],
      remoteuser:20,
      currentIndex: 0,
      IframeValue:false

    };
  }

  componentDidMount() {
    this.getIsPageInitialized();
    this.setIpToState();
    this.setState({ isForCustomerView: window.location.pathname !== '/' });
    this.writeFullDBToState();

  }

  writeFullDBToState = () => {
    axios({
      method: 'get',
      // for release
      url: '/api/db',
      // for staging
      // url: 'http://aws5.dev.ipkt.io/api/db',
    })
      .then((res) => {
      this.setState({fullDB: res.data})
      })
      .catch(error => {
        console.log(error, '222');
      })

  }

  setIpToState = () => {
    const { storage } = this.state;
    const componentContext = this;
    axios({
      method: 'get',
      // for release
      url: 'https://api.ipify.org',
    })
      .then(res => {
        const newStorage = _.cloneDeep(storage);
        newStorage.currentIp = res?.data;
        componentContext.setState({ storage: newStorage });
      })
      .catch(error => {
        console.log(error, '222');
      })
  }

  updateStorageItem = (name, value) => {
    const newStorage = this.state.storage;
    newStorage[name] = value;
    if (name === 'isCustomerView') {
      this.setState({ isPricingShown: value });
    }
    else {
      this.setState({ storage: newStorage, isShow: value });
    }
  };

  updateDatabase = () => {
    const { storage } = this.state;
    const { isSave } = storage;
    if (isSave === true) {
      let formData = new FormData();
      formData.append('slug', storage?.customerName && sha256(storage?.customerName));
      formData.append('json', JSON.stringify(storage));
      axios({
        method: 'post',
        // for staging
        // url: 'http://aws5.dev.ipkt.io/api/updateJSON',
        // for demo
        url: '/api/updateJSON',
        data: formData,
      }).then((response) => {
      }).catch((error) => {
      });
    }
  }

  getBranchOfficesNumber = () => {
    const { branchValuesList } = this.state.storage;
    // console.log("bValue",branchValuesList)
    let sum = 0;

if (branchValuesList) {
  branchValuesList.forEach((el) => {
    const num = parseInt(el, 10); // Parse the string as an integer
    if (!isNaN(num)) { // Check if the parsing was successful
      sum += num; // Add the number to the sum
    }
  });
}
return sum;
    // let number = 0;
    // branchValuesList?.map((el) => {
    //   if (el > 0) {
    //     number++;
    //   }
    // });

    // return number

  }

  isMockedPage = () => {
    return window.location.pathname === YOUR_NETWORK_PAGE_REDIRECT_PATHNAME || window.location.pathname === YOUR_NETWORK_PAGE_NO_REDIRECT_PATHNAME
  }

  getIsPageInitialized = () => {
    if (this.isMockedPage()) {
      const tempObjToState = this.state.storage;
      tempObjToState.branchValuesList = [0,0];
      tempObjToState.dataCentersValueList = [0,0];
      tempObjToState.isAWS = true;
      tempObjToState.isAntiPhishing = false;
      tempObjToState.isAzure = true;
      tempObjToState.isFWaaS = true;
      tempObjToState.isGCP = true;
      tempObjToState.isIPS = true;
      tempObjToState.fileEncodedURL = getInsidepacketLogo();
      tempObjToState.isManagedSOC = false;
      tempObjToState.isMultiCloud = true;
      tempObjToState.isSecuredWebFiltering = false;
      tempObjToState.isWebClassification = true;
      tempObjToState.remoteUsers = 0;
      tempObjToState.isDDOSProtection = false;
      tempObjToState.isBOX = true;
      tempObjToState.isSalesforce = true;
      tempObjToState.isOffice = true;
      tempObjToState.customerName = 'Your Network';
      tempObjToState.isSave = false;
      tempObjToState.is90sec = window.location.pathname === YOUR_NETWORK_PAGE_REDIRECT_PATHNAME;
      tempObjToState.customCloudConnectivityValuesList =
        [];
      tempObjToState.customSAASConnectivityValuesList =
        [];

      this.setState({ storage: tempObjToState, isAuth: true})
      if (tempObjToState?.is90sec) {
        setTimeout(() => {
          let timerInterval
          Swal.fire({
            html: `We hope we piqued your interest in our services today.
          You are now being re-directed to a contact details form, please leave your contact details to continue.
          You will be re-directed in <b></b> seconds.`,
            timer: 10000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
              timerInterval = setInterval(() => {
                b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
              }, 1000)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            location.href = 'https://www.insidepacket.com/details-form/';
          })
        }, 90000);
      }
    }
    else {
      axios({
        method: 'get',
        // for staging
        // url: `${'http://aws5.dev.ipkt.io/api/c' + window.location.pathname + `/json`}`,
        // for demo
        url: `${'/api/c' + window.location.pathname + `/json`}`,
      }).then((response) => {
        const tempObjToState = this.state.storage;
        const finalObjFromServer = response.data;
  
        tempObjToState.branchValuesList = finalObjFromServer.branchValuesList;
        tempObjToState.dataCentersValueList = finalObjFromServer.dataCentersValueList;
        tempObjToState.isAWS = finalObjFromServer.isAWS;
        tempObjToState.isAntiPhishing = finalObjFromServer.isAntiPhishing;
        tempObjToState.isAzure = finalObjFromServer.isAzure;
        tempObjToState.isFWaaS = finalObjFromServer.isFWaaS;
        tempObjToState.isGCP = finalObjFromServer.isGCP;
        tempObjToState.isIPS = finalObjFromServer.isIPS;
        tempObjToState.fileEncodedURL = finalObjFromServer.fileEncodedURL;
        tempObjToState.isManagedSOC = finalObjFromServer.isManagedSOC;
        tempObjToState.isMultiCloud = finalObjFromServer.isMultiCloud;
        tempObjToState.isSecuredWebFiltering = finalObjFromServer.isSecuredWebFiltering;
        tempObjToState.isWebClassification = finalObjFromServer.isWebClassification;
        tempObjToState.remoteUsers = finalObjFromServer.remoteUsers;
        tempObjToState.isDDOSProtection = finalObjFromServer.isDDOSProtection;
        tempObjToState.isBOX = finalObjFromServer.isBOX;
        tempObjToState.isSalesforce = finalObjFromServer.isSalesforce;
        tempObjToState.isOffice = finalObjFromServer.isOffice;
        tempObjToState.customerName = finalObjFromServer.customerName;
        tempObjToState.isSave = finalObjFromServer.isSave;
        tempObjToState.is90sec = finalObjFromServer.is90sec;
        tempObjToState.customCloudConnectivityValuesList =
          finalObjFromServer.customCloudConnectivityValuesList;
        tempObjToState.customSAASConnectivityValuesList =
          finalObjFromServer.customSAASConnectivityValuesList;
        this.setState({ storage: tempObjToState, isAuth: true });
        if (finalObjFromServer?.is90sec) {
          setTimeout(() => {
            let timerInterval
            Swal.fire({
              html: `We hope we piqued your interest in our services today.
            You are now being re-directed to a contact details form, please leave your contact details to continue.
            You will be re-directed in <b></b> seconds.`,
              timer: 10000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                  b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
                }, 1000)
              },
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then((result) => {
              location.href = 'https://www.insidepacket.com/details-form/';
            })
          }, 90000);
        }
  
      })
        .catch((error) => {
          console.log(error);
          let shouldSwalBeClosed = false;
          Swal.fire({
            title: 'Please log in',
            html:
              '<input id="authUsername" placeholder="Username" class="swal2-input">' +
              '<input id="authPassword" placeholder="Password" type="password" class="swal2-input">',
            focusConfirm: false,
            preConfirm: () => {
              axios({
                method: 'post',
                url: '/api/auth',
                data: {},
                auth: {
                  username: document.getElementById('authUsername').value,
                  password: document.getElementById('authPassword').value
                },
              }).then((response) => {
                Swal.close();
                shouldSwalBeClosed = true;
                this.setState({ isAuth: true, username: document.getElementById('authUsername').value, password: document.getElementById('authPassword').value });
              }).catch((error) => {
                Swal.showValidationMessage(
                  `Request failed: wrong credentials`
                );
                shouldSwalBeClosed = false;
                this.setState({ isAuth: false });
              });
              return shouldSwalBeClosed
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        });
    }
  }
  // toggleDDOSProtection = () => {
  //   this.setState((prevState) => ({
  //     storage: {
  //       ...prevState.storage,
  //       isDDOSProtection: !prevState.storage.isDDOSProtection,
  //     },
  //   }));
  // };

  render() {
    // console.log(this.state.storage,"carosal")
    const { username, password, isForCustomerView, fullDB,carosal } = this.state;
    if (!this.state.isAuth) {
      return null
    }
    return (
      <>
        <div style={{ display: 'block', height: '100%', width: '100%' }}>
          <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          
            <form class="aside box">
              <div
                class="aside__scrollbar_container"
                id="aside_scrollbar_container"
              >
                <Aside_scrollbar_container
                  updateStorageItem={this.updateStorageItem}
                  storage={this.state.storage}
                  username={username} password={password}
                  updateDatabase={this.updateDatabase}
                  isForCustomerView={isForCustomerView}
                  
                  fullDB={fullDB}
                  remoteuser={(e)=> {this.setState((prevState) => ({
                    remoteuser: e,
                  }))
                }}
                IframeValue={
                  (e)=>{
                    this.setState({
                      IframeValue:e,
                      carosal:[],
                    })
                  }
                }
                  carosal={(e)=> {
                    // console.log(e,"testing2")
                    this.setState((prevState) => ({
                    carosal: e,
                    currentIndex: 0,
                  }))
                }}
                handleIframeValue={
                  (e)=>this.setState({
                    IframeValue:e
                  })
                }
                />
              </div>
            </form>

            <main class="main box" style={!isForCustomerView?{overflowY:"scroll"}:{overflowY:"scroll"}}>
              <div class="main__area_container" id="main_area_container" > 
                <Main_area_container
                  updateStorageItem={this.updateStorageItem}
                  // toggleDDOSProtection={this.toggleDDOSProtection} 
                  storage={this.state.storage}
                  setState={(bool)=>{
                    this.setState(prevState => ({
                      storage: {
                        ...prevState.storage,  // Copy the previous storage object
                        isDDOSProtection: bool // Update the isDDOSProtection property
                      }
                    }))
                    return this.state.storage
                  }}
                  username={username}
                  password={password}
                  updateDatabase={this.updateDatabase}
                  isForCustomerView={isForCustomerView}
                  getBranchOfficesNumber={this.getBranchOfficesNumber}
                  fullDB={fullDB}
                  carosal={carosal}
                  IframeValue={this.state.IframeValue}
                  handleIframeValue={
                    (e)=>this.setState({
                      IframeValue:e
                    })
                  }
                  currentSlide={this.state.currentIndex}
                  currentIndex={(e)=> {
                   
                    this.setState((prevState) => ({
                    
                    currentIndex: e,
                  }))
                }}
                carosalArray={(e)=>
                  this.setState((prevState) => ({
                    
                    carosal: e,
                  }))
                }
                />
              </div>
              {!this.state.isPricingShown && <div id="main_action_container"  style={this.state.carosal.length>0 ||this.state.IframeValue?{display:"none"}:{}}> 
                <Main_action_container
                  storage={this.state.storage}
                  updateDatabase={this.updateDatabase}
                  isForCustomerView={isForCustomerView}
                  getBranchOfficesNumber={this.getBranchOfficesNumber}
                  fullDB={fullDB}
                  remoteuser={this.state.remoteuser}
                />
               
              </div>}

      
            </main>
            

            <script
              src="https://unpkg.com/react@17/umd/react.development.js"
              crossorigin
            ></script>
            <script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>
            <script
              src="https://unpkg.com/react-dom@17/umd/react-dom.development.js"
              crossorigin
            ></script>
            <script src="https://smtpjs.com/v3/smtp.js"></script>
            <script src="./js/bundle.js"></script>
          </div>
          <Main_bottom_line
            updateStorageItem={this.updateStorageItem}
            storage={this.state.storage}
            updateDatabase={this.updateDatabase}
            isForCustomerView={isForCustomerView}
            fullDB={fullDB}
          />
        </div>
      </>
    );
  }
}

ReactDOM.render(<Wrapper />, document.getElementById("root"));
