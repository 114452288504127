import React from "react";
import ReactDOM from "react-dom";
import Popup from 'reactjs-popup';


class Main_bottom_line extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCustomerView: false,
            markup: 0,
        };
    }

    componentDidMount() {
      const {isCustomerView, markup} = this.state;
      this.props.updateStorageItem('markup', markup);
      this.props.updateStorageItem('isCustomerView', isCustomerView);
    }

    toggleCheckbox = (entity, value) => () => {
        this.setState({ [entity]: !value });
        // this.props.updateStorageItem(entity, !value);
    };

    editField =
        (entity) =>
            ({ target }) => {
                const { value } = target;
                this.props.updateStorageItem(entity, value);

                this.setState({ [entity]: value });
            };

    render() {
        const { isCustomerView, markup } = this.state;
        const {isForCustomerView} = this.props;
        return (
            <div class="bt">
                {!isForCustomerView && <Popup
                    contentStyle={{
                        height: '8rem',
                        width: '13rem',
                        background: '#FDFCFE',
                        border: '1px solid #E2E2E8',
                        boxShadow: '10px 10px 20px rgba(120, 118, 148, 0.1)',
                        borderRadius: '6px',
                    }}
                    trigger={open => (
                       <div class="bt__config">
                            MSSP configuration
                        </div>
                    )}
                    position="top"
                    closeOnDocumentClick
                >
                    <div className="bt__settings_wrapper">
                        <div className="aside__part_item checkbox">
                            <input
                                type="checkbox"
                                id="chCustomer"
                                checked={isCustomerView}
                                onClick={this.toggleCheckbox("isCustomerView", isCustomerView)}
                            />
                            <label htmlFor="chCustomer">
                                <span>Customer view</span>
                                <div className="icon">
                                    <div className="icon__in">
                                        <svg
                                            width="11"
                                            height="8"
                                            viewBox="0 0 11 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M3.62829 6.1773L0.947745 3.59953L0 4.51089L3.62829 8L11 0.91136L10.0523 8.60397e-06L3.62829 6.1773Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div>
                            <div className="aside__part_item input">
                                <span>Markup, %</span>
                                <input
                                    onChange={this.editField("markup")}
                                    type="text"
                                    value={markup}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>}

                <div className="bt__title">
                    <div class="bt__img">
                        <img src="./img/bt.svg" />
                    </div>
                    <div class="bt__text">Powered by InsidePacket</div>
                </div>

            </div>
        )
    }
}

export default Main_bottom_line;
